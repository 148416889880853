<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
      <li class="page-item">
        <a
          class="page-link"
          @click="updateData(index - 1)"
          :class="current == 1 ? 'disabled-link' : ''"
        >
          <i class="bi bi-chevron-left"></i>
        </a>
      </li>
      <!-- Step 1 -->
      <template v-if="current < 8">
        <template v-for="index in totalData" :key="index">
          <li
            class="page-item"
            v-if="index <= 10"
            :class="current == index ? 'active' : ''"
          >
            <a
              @click="updateData(index)"
              class="page-link"
              :class="current == index ? 'disabled-link' : ''"
              >{{ index }}</a
            >
          </li>
        </template>
        <template v-if="totalData > 7">
          <li>
            <a class="page-link disabled-link">...</a>
          </li>
          <li
            class="page-item"
            :class="current == totalData - 1 ? 'active' : ''"
          >
            <a
              @click="updateData(totalData - 1)"
              class="page-link"
              :class="current == totalData - 1 ? 'disabled-link' : ''"
            >
              {{ totalData - 1 }}
            </a>
          </li>
          <li class="page-item" :class="current == totalData ? 'active' : ''">
            <a
              @click="updateData(totalData)"
              class="page-link"
              :class="current == totalData ? 'disabled-link' : ''"
            >
              {{ totalData }}
            </a>
          </li>
        </template>
      </template>
      <!-- Step 2 -->
      <template v-else-if="current <= totalData - 7">
        <li class="page-item" :class="current == 1 ? 'active' : ''">
          <a
            @click="updateData(1)"
            class="page-link"
            :class="current == 1 ? 'disabled-link' : ''"
            >1</a
          >
        </li>
        <li class="page-item" :class="current == 2 ? 'active' : ''">
          <a
            @click="updateData(2)"
            class="page-link"
            :class="current == 2 ? 'disabled-link' : ''"
            >2</a
          >
        </li>
        <li>
          <a class="page-link disabled-link">...</a>
        </li>
        <template v-for="index in totalData" :key="index">
          <li
            class="page-item"
            v-if="index >= current - 2 && index <= current + 2"
            :class="current == index ? 'active' : ''"
          >
            <a
              @click="updateData(index)"
              class="page-link"
              :class="current == index ? 'disabled-link' : ''"
              >{{ index }}</a
            >
          </li>
        </template>
        <li>
          <a class="page-link disabled-link">...</a>
        </li>
        <li class="page-item" :class="current == totalData - 1 ? 'active' : ''">
          <a
            @click="updateData(totalData - 1)"
            class="page-link"
            :class="current == totalData - 1 ? 'disabled-link' : ''"
          >
            {{ totalData - 1 }}
          </a>
        </li>
        <li class="page-item" :class="current == totalData ? 'active' : ''">
          <a
            @click="updateData(totalData)"
            class="page-link"
            :class="current == totalData ? 'disabled-link' : ''"
          >
            {{ totalData }}
          </a>
        </li>
      </template>
      <!-- Step 3 -->
      <template v-else>
        <li class="page-item" :class="current == 1 ? 'active' : ''">
          <a
            @click="updateData(1)"
            class="page-link"
            :class="current == 1 ? 'disabled-link' : ''"
            >1</a
          >
        </li>
        <li class="page-item" :class="current == 2 ? 'active' : ''">
          <a
            @click="updateData(2)"
            class="page-link"
            :class="current == 2 ? 'disabled-link' : ''"
            >2</a
          >
        </li>
        <li>
          <a class="page-link disabled-link">...</a>
        </li>
        <template v-for="index in totalData" :key="index">
          <li
            class="page-item"
            v-if="index >= totalData - 10"
            :class="current == index ? 'active' : ''"
          >
            <a
              @click="updateData(index)"
              class="page-link"
              :class="current == index ? 'disabled-link' : ''"
              >{{ index }}</a
            >
          </li>
        </template>
      </template>
      <li class="page-item">
        <a
          class="page-link"
          @click="updateData(index + 1)"
          :class="current == totalData ? 'disabled-link' : ''"
        >
          <i class="bi bi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  props: {
    totalData: Number,
    currentRow: Number,
  },
  setup(props, { emit }) {
    const current = ref(1);
    watch(
      () => props.currentRow,
      () => {
        current.value = props.currentRow;
      }
    );
    const updateData = (index) => {
      emit("setRows", index);
      current.value = index;
    };

    return {
      updateData,
      current,
    };
  },
});
</script>
